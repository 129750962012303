const encodedDomains = [
    'bG9jYWxob3N0',
    'YmxvZy5ic2d1bi5jbg==',
    'YmxvZy5ydW9tLnRvcA==',
    'amxpbm1yLmdpdGh1Yi5pbw==',
    'YmxvZy4xMTk5OTAueHl6'
];

const decode = str => atob(str);
const encodedRedirect = 'aHR0cHM6Ly9ibG9nLmJzZ3VuLmNu';

const allowedDomains = new Set(encodedDomains.map(decode));

if (!allowedDomains.has(document.domain)) {
    Snackbar.show({
        text: '检测到非法访问,即将返回原始站点',
        pos: 'top-center',
        actionText: '确定',
        duration: 5000,
        onActionClick: () => window.location.href = decode(encodedRedirect)
    });

    setTimeout(() => window.location.href = decode(encodedRedirect), 5000);
}